export const customScrollBar = `
    scrollbar-width: thin;
    scrollbar-color: var(--neutral10) var(--neutral90);
    &:hover{
      scrollbar-color: var(--neutral20) var(--neutral90);
    }
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      background: var(--neutral90);
    }
    &::-webkit-scrollbar-thumb {
      background: var(--neutral10);
      border-radius: 0px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: var(--neutral20);
    }
`

export const hiddenScrollBar = `
  scrollbar-width: thin;
  scrollbar-color: var(--neutral90) transparent;
  &:hover{
    scrollbar-color: var(--neutral20) transparent;
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--neutral90);
    border-radius: 0px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--neutral80);
  }
`

export default customScrollBar
