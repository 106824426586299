import React from 'react'
import Image from 'next/legacy/image'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { P, H3 } from 'common/typography'

const ReviewStyled = styled.article`
  scroll-snap-align: start;
  padding: 40px;
  border-radius: 16px;
  backdrop-filter: blur(90.9968px);
  padding: 2.27px;
  overflow: hidden;
  height: fit-content;
  margin-block-end: 16px;
  ${({ idx }) => ((idx % 2 === 0)
    ? `
      background: linear-gradient(147.52deg, #874053 23.89%, #9499A6 67.19%);
    `
    : `
        background: linear-gradient(147.52deg, #36DFE5 23.89%, #9499A6 67.19%);
      `
  )};
  .review-container {
    color: #DFFBFF;
    border-radius: 16px;
    ${({ idx }) => ((idx % 2 === 0)
    ? `
        background: linear-gradient(0deg, rgba(41, 21, 68, 0.5), rgba(41, 21, 68, 0.5)), linear-gradient(123.82deg, #AC4F68 -13.64%, #0E0230 87.27%);
        `
    : `
        background: linear-gradient(0deg, rgba(41, 21, 68, 0.5), rgba(41, 21, 68, 0.5)), linear-gradient(123.82deg, #209EAF -13.64%, #282F42 87.27%);
    `)};
    padding: 24px;
  }
  .country {
    flex-shrink: 0;
    align-self: flex-start;
    margin-left: .5em;
    position: relative;
    bottom: 11px;
  }
  .avatar {
    margin: 2px;
    margin-right: 16px;
    object-fit: cover;
    transition: .2s;
    border-radius: 8px;
    overflow: hidden;
    width: 80px;
    height: 80px;
    background: var(--secondary);
    &:hover {
      box-shadow: 0 0 0 2px #1da1f2;
    }
  }
  .author {
    height: fit-content;
    display: flex;
    margin-bottom: 16px;
    &-details {
      margin: 2px 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &-details p {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical
    }
  }
  h4 {
    display: flex;
  }
  @media(min-width: 1024px) {
    margin-block-end: 24px;
    .review-container {
      padding: 40px;
    }
  }
`

function Review({
  image, name, topic, country, text, twitter, idx,
}) {
  return (
    <ReviewStyled idx={idx}>
      <div className="review-container">
        <div className="author">
          <a href={twitter} target="_blank" rel="noopener noreferrer" className="avatar" aria-label={`Avatar de ${name}`}>
            <Image
              width="80"
              height="80"
              src={image}
              alt={name}
              loading="lazy"
            />
          </a>
          <div className="author-details">
            <H3 fontSize="20" lineHeight="28" color="var(--white)">
              {name}
            </H3>
            <P fontSize="16" lineHeight="24" color="var(--white)">{topic}</P>
          </div>
        </div>
        <P fontSize="16" lineHeight="24" color="var(--white)">{text}</P>
      </div>
    </ReviewStyled>
  )
}

Review.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  topic: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  twitter: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
}

const ReviewMemo = React.memo(Review)

export default ReviewMemo
