const reviews = [
  [
    {
      image: '/images/home/review/marian-villa.jpg',
      name: 'Marian Villa',
      topic: 'UI/UX | Founder | CEO | Director 👩‍💼 @pionerasdev',
      country: '/images/flags/co.jpg',
      twitter: 'https://twitter.com/Marianvilla',
      text: 'Leo no solo es un gran profesional, es un gran ser humano. Admiro su profesionalismo y atención al detalle. Siempre sé que vienen cosas grandes de él y este curso no es la excepción, sé que todo Latam  aprenderemos montones aquí. 💪',
    },
    {
      image: '/images/home/review/karime-jalil.jpg',
      name: 'Karime Jalil',
      topic: 'Entrepreneur. People&Culture. HR. Startups . LATAM',
      country: '/images/flags/mx.jpg',
      twitter: 'https://twitter.com/karimegjalil',
      text: 'Su pasión por enseñar pero sobre todo por aprender la contagia en todo lo que hace, Leonidas es una gran persona que siempre nos recuerda que no hay que rendirnos nunca!!',
    },
    {
      image: '/images/home/review/josue-gutierrez.jpg',
      name: 'Josue Gutierrez',
      topic: 'Google Developer expert',
      country: '/images/flags/mx.jpg',
      twitter: 'https://twitter.com/eusoj',
      text: 'Si quieres aprender sobre Web, Leo es el mejor maestro! Hacía falta contenido de esta calidad en el continente! Recomendado 100% sin importar el nivel que tengas. 👍',
    },
    {
      image: '/images/home/review/antonio-guzman.jpg',
      name: 'Antonio Guzmán',
      topic: 'Google Customer Engineer',
      country: '/images/flags/mx.jpg',
      twitter: 'https://twitter.com/tonioguzmanf',
      text: 'Leo representa muchos de los mejores valores que identifico en las personas que te pueden ayudar a alcanzar nuevos niveles profesionales porque es alegre, honesto, empático y siempre está decidido a hacer más sólidos sus conocimientos porque sabe que tiene un compromiso con su comunidad.',
    },
    {
      image: '/images/home/review/gustavo-delgado.jpg',
      name: 'Gustavo Delgado',
      topic: 'VP of Engineering',
      country: '/images/flags/mx.jpg',
      twitter: 'https://www.linkedin.com/in/gustavo-delgado-alvarez-2306a8139/',
      text: 'Trabajamos juntos por dos años y durante ese tiempo siempre demostró una gran empatía a todos los retos que tuvimos que superar y ayudó mucho en fomentar la cultura que teníamos en el equipo. A nivel técnico también se encargo de crear una estructura escalable para todos nuestro desarrollos front end y tener las mejores prácticas para asegurar un buen servicio final a nuestro usuarios. Tanto a nivel personal como profesional considero a Leo una excelente persona y espero tener la oportunidad de coincidir nuevamente con él en algún proyecto en el futuro 🙂',
    },
  ],
  [
    {
      image: '/images/home/review/joel-humberto.jpg',
      name: 'Joel Humberto Gómez Paredes',
      topic: 'GDE Web Technologies && Google Maps Platform',
      country: '/images/flags/mx.jpg',
      twitter: 'https://twitter.com/DezkaReid',
      text: 'Leonidas es una persona muy apasionada en sus proyectos y en todo lo que hace, siempre con una gran actitud. Siempre busca como mejorar y hacer mejor su trabajo, como profesional y como ser humano es una excelente persona',
    },
    {
      image: '/images/home/review/laura-morinigo.jpg',
      name: 'Laura Morinigo',
      topic: 'Developer Advocate en Samsung',
      country: '/images/flags/ar.jpg',
      twitter: 'https://twitter.com/thisislalaok',
      text: 'Leonidas es un referente de las tecnologías web dejando bien en alto a la comunidad hispana. Su pasión por enseñar siempre ha sido de gran inspiración y un lugar de referencia para convertirte en experto!',
    },
    {
      image: '/images/home/review/anahi-salgado.jpg',
      name: 'Anahí Salgado',
      topic: 'Android Dev, Flutter, Geek and Teacher',
      country: '/images/flags/mx.jpg',
      twitter: 'https://twitter.com/anncode',
      text: 'Tener a Leonidas como profe es una de las experiencias más divertidas y llenas de confianza que puedas tener. Leo siempre está preocupado por entregar el contenido de la manera más estructurada, profesional y coherente posible de tal forma que siempre te lleves una experiencia en tu aprendizaje. Desde que lo conozco para mí es sinónimo de calidad y motivación. Es genial ser alumno de Leonidas.',
    },
    {
      image: '/images/home/review/jorge-cano.jpg',
      name: 'Jorge Cano',
      topic: 'Google Developer Expert & Principal Architect en Hero Devs',
      country: '/images/flags/ar.jpg',
      twitter: 'https://twitter.com/jorgeucano',
      text:
        'Siento que hay poca gente que puede tener la pasión por la educacion como Leonidas, y lo demuestra en cada  contenido que hace... Es del poco contenido que consumo en español!',
    },
    {
      image: '/images/home/review/vanessa-marely.jpg',
      name: 'Vanessa Marely',
      topic: 'GDE Angular & Web Technologies',
      country: '/images/flags/co.jpg',
      twitter: 'https://twitter.com/vanessamarely',
      text: 'Leonidas es un gran profesional, increíble persona y un docente apasionado; en todos sus cursos siempre aprendemos de él y encontramos contenido de muy buena calidad, y este curso no es la excepción,  en él aprenderás mucho sobre lo que la industria nos está pidiendo.',
    },
  ],
  [
    {
      image: '/images/home/review/guillermo-rodas.jpg',
      name: 'Guillermo Rodas',
      topic: 'Google Developer Expert',
      country: '/images/flags/se.jpg',
      twitter: 'https://twitter.com/glrodasz',
      text: 'Hace años que sigo el trabajo de Leonidas y siempre me ha sorprendido la disponibilidad y facilidad que tiene para explicar conceptos básicos y avanzados de programación. Su pasión por la comunidad y ganas de cambiarle la vida a las personas mediante la educación hacen que sus clases conecten con los estudiantes entregando contenido de alta calidad.',
    },
    {
      image: '/images/home/review/fazt-tech.jpg',
      name: 'Fazt',
      topic: 'Fundador de FaztTech y FaztCode',
      country: '/images/flags/pe.jpg',
      twitter: 'https://twitter.com/fazttech',
      text: 'Leonidas es un excelente profesional, altamente enfocado en su trabajo y siempre buscando la mejora continua en lo que respecta a educación.',
    },
    {
      image: '/images/home/review/pablo-villanueva.jpg',
      name: 'Pablo Villanueva',
      topic: 'Customer Engineer en Google',
      country: '/images/flags/mx.jpg',
      twitter: 'https://twitter.com/pablovillamx',
      text: 'Con Leo te divertirás, serás parte de una gran comunidad y llevarás tus habilidades de desarrollo al siguiente nivel. No importa en donde te encuentres en tu viaje profesional, estoy convencido que encontrarás gran valor en los cursos y contenidos de Leo, que sus explicaciones serán claras, concisas y de alta calidad, y que seguro sales con un trucazo nuevo en cada interacción!',
    },
    {
      image: '/images/home/review/sergio-xalambri.jpg',
      name: 'Sergio Xalambrí',
      topic: 'Frontend Lead en Able',
      country: '/images/flags/ar.jpg',
      twitter: 'https://twitter.com/sergiodxa',
      text: 'Leonidas es de los mejores Frontend Dev y profesores que conozco, siempre que veo contenido suyo se que va a ser de una calidad increíble.',
    },
    {
      image: '/images/home/review/devpato.jpg',
      name: 'Pato',
      topic: 'Google Developer Expert',
      country: '/images/flags/us.jpg',
      twitter: 'https://twitter.com/devpato',
      text: 'Yo he aprendido mucho de Leonidas desde como enseña, su amor por ayudar y algunos trucazos por ahí también. Leonidas no solo te hará crecer tus conocimientos técnicos, pero también como crecer como persona y profesional.',
    },
  ],
]

export default reviews
