import React from 'react'
import styled from 'styled-components'
import { Typography } from 'common/typography'
import Wrapper from 'common/wrapper'
import Review from './review'
import reviews from './reviews'

const ComunityReviewsStyled = styled.section`
  h2 {
    max-width: 750px;
  }
  .reviews {
    margin-block-start: 24px;
  }
  @media screen and (min-width: 768px) {
    .button {
      width: 403px;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 1024px) {
    margin-block-end: 80px;
    .reviews {
      display: flex;
      gap: 24px;
      margin-block-start: 48px;
    }
    .review-column {
      flex: 1;
      display: block;
      &.even {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
      }
    }
  }
`

const reviewsIds = ['group-1', 'group-2', 'group-3']

function ComunityReviews() {
  return (
    <ComunityReviewsStyled aria-labelledby="community-reviews">
      <Wrapper maxWidth="1360">
        <Typography as="h2" id="community-reviews" small="headline5" large="headline3" color="baseNeutral">
          Opiniones de profesionales en la industria
        </Typography>
        <section className="reviews">
          {reviews.map((reviewGroup, index) => (
            <div key={reviewsIds[index]} className={`review-column ${index % 2 === 0 ? '' : 'even'}`}>
              {reviewGroup.map((review, idx) => (
                <Review
                  key={review.name}
                  idx={index % 2 === 0 ? idx : idx + 1}
                  image={review.image}
                  name={review.name}
                  topic={review.topic}
                  country={review.country}
                  text={review.text}
                  twitter={review.twitter}
                />
              ))}
            </div>
          ))}
        </section>
      </Wrapper>
    </ComunityReviewsStyled>
  )
}

export default ComunityReviews
