import styled from 'styled-components'

const ProgresiveLearningStyled = styled.section`
  padding: 64px 0;
  overflow-x: hidden;
  .svg-container {
    position: relative;
  }
  .gosth {
    z-index: -1;
    display: block;
    margin: 0 auto;
    &.partial {
      max-width: 404px;
      max-height: 123px;
    }
    &.completed {
      max-width: 578px;
      max-height: 342px;
    }
  }
  .step {
    display: block;
    &.first {
      margin-top: 80px;
      margin-bottom: 200px;
    }
    &.second {
      margin-bottom: 200px;
      img {
        max-width: 80%;
      }
    }
    &.first, &.third {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  p {
    max-width: 430px;
    position: relative;
    padding-left: 24px;
    margin-top: 48px;
    font-weight: normal;
    color: var(--paragraphColor);
    &::before {
      position: absolute;
      content: '';
      left: 0;
      top: 8px;
      display: block;
      width: 12px;
      height: 12px;
      background: var(--turquoise);
      border-radius: 50%;
    }
  }
  img {
    max-width: 100%;
    margin-bottom: 48px;
    height: auto;
  }
  .mobile-hidden {
    display: none;
  }
  .desktop {
    display: none;
  }
  @media (min-width: 1024px) {
    padding: 80px 0 128px;
    .gosth {
      margin: 0;
    }
    .step {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.first {
        margin-top: 120px;
      }
      &.second {
        img {
          max-width: 100%;
        }
      }
      .third {
        margin-bottom: 0px;
      }
      &.first, &.third {
        display: flex;
        flex-direction: row;
      }
    }
    img {
      margin: 0;
    }
  }
  @media (min-width: 1300px) {
    p {
      margin-top: 0;
    }
    .desktop-hidden {
      display: none;
    }
    .mobile-hidden {
      display: block;
    }
    .blocks-moving-container {
      min-width: 564px;
      min-height: 225px;
      position: relative;
      height: 100%;
      .gosth {
        margin: auto 0;
        opacity: 0;
        &.dispersed {
          max-width: 564px;
          max-height: 225px;
          opacity: 1;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
        }
      }
      .blocks-moving {
        &.gosth {
          opacity: 1;
        }
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
      }
    }
    .responsive {
      display: none;
    }
    .desktop {
      display: block;
    }
    .steps-container {
      position: relative;
      width: 100%;
      height: calc(70vh - var(--headerBlockSize));
      overflow: visible;
      .step {
        overflow: visible;
        position: absolute;
        margin: 0;
        height: calc(calc(100vh - var(--headerBlockSize)) / 2);
        right: 0;
        left: 0;
      }
    }
  }
`

export default ProgresiveLearningStyled
