import { memo, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { Timeline, Tween, ScrollTrigger } from 'react-gsap'
import Wrapper from 'common/wrapper'
import useIntersectionObserver from 'hooks/use-intersection-observer'
import ProgresiveLearningStyled from './progressive-learning-styles'
import ProgresLearningElements from './progresive-elements'

// SCROLL TRIGER DOCS:
// https://greensock.com/docs/v3/Plugins/ScrollTrigger

// TWEEN DOCS:
// We use position="<" to start at the same time: https://greensock.com/docs/v3/GSAP/Timeline

function ProgresiveLearning({ reviewCount }) {
  const progresiveTimeLine = useRef(null)
  const { query: { course } } = useRouter()

  const { ref: section } = useIntersectionObserver({
    onIntersect: () => {
      if (!window.location.hash) {
        progresiveTimeLine.current?.getGSAP().scrollTrigger?.refresh()
      }
    },
    rootMargin: '0px 0px 500px 0px',
  })

  useEffect(() => {
    progresiveTimeLine.current?.getGSAP().scrollTrigger?.refresh()
  }, [progresiveTimeLine.current, course, reviewCount])

  return (
    <ProgresiveLearningStyled aria-labelledby="progresive-title" ref={section}>
      <Wrapper maxWidth="1200">
        <div className="responsive">
          <ProgresLearningElements unique="mobile" />
        </div>
        <div className="desktop">
          <ScrollTrigger
            trigger="trigger"
            start="top 25%"
            end="+=2000"
            scrub
            pin
          >
            <Timeline
              target={<ProgresLearningElements unique="desktop" />}
              ref={progresiveTimeLine}
            >
              <Tween
                from={{
                  css: {
                    translateX: '-80vw',
                    translateY: '159px',
                  },
                }}
                to={{
                  css: {
                    translateX: '77px',
                    translateY: '159px',
                  },
                }}
                target="yellowCube"
              />
              <Tween
                from={{
                  css: {
                    translateX: '283px',
                    translateY: '-100vh',
                  },
                }}
                to={{
                  css: {
                    translateX: '283px',
                    translateY: '13px',
                  },
                }}
                target="blueCube"
                position="<"
              />
              <Tween
                from={{
                  css: {
                    translateX: '70vw',
                    translateY: '115px',
                  },
                }}
                to={{
                  css: {
                    translateX: '487px',
                    translateY: '115px',
                  },
                }}
                target="pinkCube"
                position="<"
              />
              <Tween
                from={{
                  css: {
                    opacity: 0,
                    translateX: '-50vw',
                  },
                }}
                to={{
                  css: {
                    opacity: 1,
                    translateX: 0,
                  },
                }}
                target="firstText"
                position="<"
              />
              <Tween
                from={{
                  css: {
                    opacity: 0,
                  },
                }}
                to={{
                  css: {
                    opacity: 0.5,
                  },
                }}
                target="firstGosth"
                position=">"
              />
              <Tween
                to={{
                  css: {
                    translateY: '-100%',
                  },
                }}
                target="firstRow"
                position=">"
              />
              <Tween
                to={{
                  css: {
                    opacity: 0.5,
                  },
                }}
                target="firstText"
                position="<"
              />
              <Tween
                to={{
                  css: {
                    translateX: '-634px',
                    translateY: '100%',
                  },
                }}
                target="cubeGroup"
                position="<"
              />
              <Tween
                from={{
                  css: {
                    opacity: 0,
                    translateX: '50vw',
                  },
                }}
                to={{
                  css: {
                    opacity: 1,
                    translateX: 0,
                  },
                }}
                target="secondText"
                position="<"
              />
              <Tween
                to={{
                  css: {
                    translateX: '77px',
                    translateY: '119px',
                    // translateY: '129px',
                  },
                }}
                target="yellowCube"
                position="<"
              />
              <Tween
                from={{
                  css: {
                    translateX: '283px',
                    translateY: '13px',
                  },
                }}
                to={{
                  css: {
                    translateX: '202px',
                    translateY: '65px',
                    // translateY: '75px',
                  },
                }}
                target="blueCube"
                position="<"
              />
              <Tween
                from={{
                  css: {
                    translateX: '487px',
                    translateY: '115px',
                  },
                }}
                to={{
                  css: {
                    translateX: '326px',
                    translateY: '65px',
                    // translateY: '75px',
                  },
                }}
                target="pinkCube"
                position="<"
              />
              <Tween
                from={{
                  css: {
                    opacity: 0,
                  },
                }}
                to={{
                  css: {
                    opacity: 1,
                  },
                }}
                target="secondGosth"
                position=">"
              />
              <Tween
                from={{
                  css: {
                    opacity: 1,
                  },
                }}
                to={{
                  css: {
                    opacity: 0.5,
                  },
                }}
                target="cubeGroup"
                position=">"
              />
              <Tween
                to={{
                  css: {
                    translateY: '-200%',
                  },
                }}
                position=">"
                target="firstRow"
              />
              <Tween
                to={{
                  css: {
                    translateY: '-100%',
                  },
                }}
                position="<"
                target="secondRow"
              />
              <Tween
                to={{
                  css: {
                    translateX: '618px',
                    translateY: '100%',
                  },
                }}
                target="secondGosth"
                position="<"
              />
              <Tween
                to={{
                  css: {
                    translateY: '92%',
                  },
                }}
                target="secondCubes"
                position="<"
              />
              <Tween
                to={{
                  css: {
                    opacity: 0.5,
                  },
                }}
                target="secondText"
                position="<"
              />
              <Tween
                from={{
                  css: {
                    opacity: 0,
                    translateX: '-50vw',
                  },
                }}
                to={{
                  css: {
                    opacity: 1,
                    translateX: 0,
                  },
                }}
                target="thirdText"
                position="<"
              />
              <Tween
                from={{
                  css: {
                    opacity: 0,
                    translateY: '80%',
                  },
                }}
                to={{
                  css: {
                    opacity: 1,
                    translateY: 0,
                  },
                }}
                target="thirdGosth"
                position="<"
              />
            </Timeline>
          </ScrollTrigger>
        </div>
      </Wrapper>
    </ProgresiveLearningStyled>
  )
}

const ProgresiveLearningMemo = memo(ProgresiveLearning)

export default ProgresiveLearningMemo
