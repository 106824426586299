import { useRef, useImperativeHandle, forwardRef } from 'react'
import styled from 'styled-components'
import { Typography } from 'common/typography'

const BlocksDispersedStyled = styled.svg`
  max-width: 564px;
  overflow: visible;
  .blocks-dispersed-yellow-cube_to {
    transform: translate(-80vw, 159px);
  }
  .blocks-dispersed-blue-cube_to {
    transform: translate(283px, -100vh);
  }
  .blocks-dispersed-pink-cube_to {
    transform: translate(70vw, 115px);
  }
`

const BlocksDispersed = forwardRef((props, ref) => {
  const { unique } = props
  const firstRow = useRef(null)
  const secondRow = useRef(null)
  const cubeGroup = useRef(null)
  const secondCubes = useRef(null)
  const firstText = useRef(null)
  const secondText = useRef(null)
  const thirdText = useRef(null)
  const firstGosth = useRef(null)
  const secondGosth = useRef(null)
  const thirdGosth = useRef(null)
  const yellowCube = useRef(null)
  const blueCube = useRef(null)
  const pinkCube = useRef(null)
  const trigger = useRef(null)
  useImperativeHandle(ref, () => ({
    firstRow,
    secondRow,
    cubeGroup,
    secondCubes,
    firstText,
    secondText,
    thirdText,
    yellowCube,
    blueCube,
    pinkCube,
    firstGosth,
    secondGosth,
    thirdGosth,
    trigger,
  }))
  return (
    <>
      <Typography as="h2" small="headline5" large="headline3" id="progresive-title" color="baseNeutral">
        Metodología
        {' '}
        <br />
        Progressive Learning
      </Typography>
      <div ref={trigger} className="steps-container">
        <article className="step first" ref={firstRow}>
          <div ref={firstText}>
            <Typography small="regular" large="headline5">
              Funciona como una escalera de componentes al estilo LEGO.
            </Typography>
          </div>
          <div className="blocks-moving-container">
            <img
              ref={firstGosth}
              className="gosth dispersed mobile-hidden"
              src="/images/course/blocks.png"
              alt="Bloques dispersos"
              width="564"
              height="225"
              loading="lazy"
            />
            <div className="blocks-moving-container " ref={cubeGroup}>
              <BlocksDispersedStyled className="blocks-moving" id="blocks-dispersed" viewBox="0 0 564 225" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
                <defs>
                  <linearGradient id={`blocks-dispersed-path3-fill-${unique}`} x1="305.943000" y1="234.514000" x2="65.470400" y2="-9.614030" spreadMethod="pad" gradientUnits="userSpaceOnUse">
                    <stop id="blocks-dispersed-path3-fill-0" offset="0%" stopColor="rgb(52,20,117)" />
                    <stop id="blocks-dispersed-path3-fill-1" offset="57%" stopColor="rgb(235,108,124)" />
                    <stop id="blocks-dispersed-path3-fill-2" offset="100%" stopColor="rgb(255,221,102)" />
                  </linearGradient>
                  <linearGradient id={`blocks-dispersed-path8-fill-${unique}`} x1="25.349800" y1="93.826300" x2="71.683100" y2="109.307000" spreadMethod="pad" gradientUnits="userSpaceOnUse">
                    <stop id="blocks-dispersed-path8-fill-0" offset="0%" stopColor="rgb(255,221,102)" />
                    <stop id="blocks-dispersed-path8-fill-1" offset="43%" stopColor="rgb(235,108,124)" />
                    <stop id="blocks-dispersed-path8-fill-2" offset="100%" stopColor="rgb(52,20,117)" />
                  </linearGradient>
                  <linearGradient id={`blocks-dispersed-path12-fill-${unique}`} x1="8.087870" y1="103.221000" x2="54.421100" y2="118.701000" spreadMethod="pad" gradientUnits="userSpaceOnUse">
                    <stop id="blocks-dispersed-path12-fill-0" offset="0%" stopColor="rgb(255,221,102)" />
                    <stop id="blocks-dispersed-path12-fill-1" offset="43%" stopColor="rgb(235,108,124)" />
                    <stop id="blocks-dispersed-path12-fill-2" offset="100%" stopColor="rgb(52,20,117)" />
                  </linearGradient>
                  <linearGradient id={`blocks-dispersed-path16-fill-${unique}`} x1="86.561000" y1="93.824600" x2="132.894000" y2="109.305000" spreadMethod="pad" gradientUnits="userSpaceOnUse">
                    <stop id="blocks-dispersed-path16-fill-0" offset="0%" stopColor="rgb(255,221,102)" />
                    <stop id="blocks-dispersed-path16-fill-1" offset="43%" stopColor="rgb(235,108,124)" />
                    <stop id="blocks-dispersed-path16-fill-2" offset="100%" stopColor="rgb(52,20,117)" />
                  </linearGradient>
                  <linearGradient id={`blocks-dispersed-path20-fill-${unique}`} x1="70.851100" y1="103.222000" x2="117.184000" y2="118.703000" spreadMethod="pad" gradientUnits="userSpaceOnUse">
                    <stop id="blocks-dispersed-path20-fill-0" offset="0%" stopColor="rgb(255,221,102)" />
                    <stop id="blocks-dispersed-path20-fill-1" offset="43%" stopColor="rgb(235,108,124)" />
                    <stop id="blocks-dispersed-path20-fill-2" offset="100%" stopColor="rgb(52,20,117)" />
                  </linearGradient>
                  <linearGradient id={`blocks-dispersed-path24-fill-${unique}`} x1="509.943000" y1="153.070000" x2="149.312000" y2="-248.574000" spreadMethod="pad" gradientUnits="userSpaceOnUse">
                    <stop id="blocks-dispersed-path24-fill-0" offset="0%" stopColor="rgb(97,64,137)" />
                    <stop id="blocks-dispersed-path24-fill-1" offset="33%" stopColor="rgb(42,129,177)" />
                    <stop id="blocks-dispersed-path24-fill-2" offset="67%" stopColor="rgb(0,173,172)" />
                    <stop id="blocks-dispersed-path24-fill-3" offset="100%" stopColor="rgb(16,255,228)" />
                  </linearGradient>
                  <linearGradient id={`blocks-dispersed-path29-fill-${unique}`} x1="229.353000" y1="0.048751" x2="275.687000" y2="15.529500" spreadMethod="pad" gradientUnits="userSpaceOnUse">
                    <stop id="blocks-dispersed-path29-fill-0" offset="0%" stopColor="rgb(16,255,255)" />
                    <stop id="blocks-dispersed-path29-fill-1" offset="58%" stopColor="rgb(8,156,185)" />
                    <stop id="blocks-dispersed-path29-fill-2" offset="100%" stopColor="rgb(52,20,117)" />
                  </linearGradient>
                  <linearGradient id={`blocks-dispersed-path33-fill-${unique}`} x1="213.668000" y1="9.444260" x2="260.001000" y2="24.925000" spreadMethod="pad" gradientUnits="userSpaceOnUse">
                    <stop id="blocks-dispersed-path33-fill-0" offset="0%" stopColor="rgb(16,255,255)" />
                    <stop id="blocks-dispersed-path33-fill-1" offset="58%" stopColor="rgb(8,156,185)" />
                    <stop id="blocks-dispersed-path33-fill-2" offset="100%" stopColor="rgb(52,20,117)" />
                  </linearGradient>
                  <linearGradient id={`blocks-dispersed-path37-fill-${unique}`} x1="290.564000" y1="0.047286" x2="336.898000" y2="15.528000" spreadMethod="pad" gradientUnits="userSpaceOnUse">
                    <stop id="blocks-dispersed-path37-fill-0" offset="0%" stopColor="rgb(16,255,255)" />
                    <stop id="blocks-dispersed-path37-fill-1" offset="58%" stopColor="rgb(8,156,185)" />
                    <stop id="blocks-dispersed-path37-fill-2" offset="100%" stopColor="rgb(52,20,117)" />
                  </linearGradient>
                  <linearGradient id={`blocks-dispersed-path41-fill-${unique}`} x1="274.855000" y1="9.444500" x2="321.188000" y2="24.925200" spreadMethod="pad" gradientUnits="userSpaceOnUse">
                    <stop id="blocks-dispersed-path41-fill-0" offset="0%" stopColor="rgb(16,255,255)" />
                    <stop id="blocks-dispersed-path41-fill-1" offset="58%" stopColor="rgb(8,156,185)" />
                    <stop id="blocks-dispersed-path41-fill-2" offset="100%" stopColor="rgb(52,20,117)" />
                  </linearGradient>
                  <linearGradient id={`blocks-dispersed-path45-fill-${unique}`} x1="713.943000" y1="254.832000" x2="353.312000" y2="-146.811000" spreadMethod="pad" gradientUnits="userSpaceOnUse">
                    <stop id="blocks-dispersed-path45-fill-0" offset="0%" stopColor="rgb(52,20,117)" />
                    <stop id="blocks-dispersed-path45-fill-1" offset="100%" stopColor="rgb(253,5,124)" />
                  </linearGradient>
                  <linearGradient id={`blocks-dispersed-path50-fill-${unique}`} x1="433.354000" y1="101.810000" x2="479.687000" y2="117.291000" spreadMethod="pad" gradientUnits="userSpaceOnUse">
                    <stop id="blocks-dispersed-path50-fill-0" offset="0%" stopColor="rgb(253,5,124)" />
                    <stop id="blocks-dispersed-path50-fill-1" offset="100%" stopColor="rgb(52,20,117)" />
                  </linearGradient>
                  <linearGradient id={`blocks-dispersed-path54-fill-${unique}`} x1="417.668000" y1="111.206000" x2="464.001000" y2="126.687000" spreadMethod="pad" gradientUnits="userSpaceOnUse">
                    <stop id="blocks-dispersed-path54-fill-0" offset="0%" stopColor="rgb(253,5,124)" />
                    <stop id="blocks-dispersed-path54-fill-1" offset="100%" stopColor="rgb(52,20,117)" />
                  </linearGradient>
                  <linearGradient id={`blocks-dispersed-path58-fill-${unique}`} x1="494.565000" y1="101.810000" x2="540.899000" y2="117.290000" spreadMethod="pad" gradientUnits="userSpaceOnUse">
                    <stop id="blocks-dispersed-path58-fill-0" offset="0%" stopColor="rgb(253,5,124)" />
                    <stop id="blocks-dispersed-path58-fill-1" offset="100%" stopColor="rgb(52,20,117)" />
                  </linearGradient>
                  <linearGradient id={`blocks-dispersed-path62-fill-${unique}`} x1="478.855000" y1="111.206000" x2="525.188000" y2="126.687000" spreadMethod="pad" gradientUnits="userSpaceOnUse">
                    <stop id="blocks-dispersed-path62-fill-0" offset="0%" stopColor="rgb(253,5,124)" />
                    <stop id="blocks-dispersed-path62-fill-1" offset="100%" stopColor="rgb(52,20,117)" />
                  </linearGradient>
                </defs>
                <g id="blocks-dispersed-yellow-cube_to" transform="translate(77.309225,159.894527)" ref={yellowCube}>
                  <g id="blocks-dispersed-yellow-cube" transform="translate(-77.258500,-161.820350)">

                    <path id="blocks-dispersed-path1" d="M31.388200,197.228000L31.388200,104.736000L155.002000,104.736000L155.002000,197.228000L31.388200,197.228000ZM34.478500,194.145000L34.478500,107.819000L151.911000,107.819000L151.911000,194.145000L34.478500,194.145000Z" clipRule="evenodd" fill="rgb(7,4,254)" fillOpacity="0.5" fillRule="evenodd" stroke="none" strokeWidth="1" />
                    <g id="blocks-dispersed-g1" opacity="0.9">
                      <g id="blocks-dispersed-g2" opacity="0.9">
                        <path id="blocks-dispersed-path2" d="M0,215.728000L0,123.235000L30.903400,104.737000L154.517000,104.737000L154.517000,197.229000L123.613000,215.728000L0,215.728000Z" opacity="0.9" clipRule="evenodd" fill="rgb(255,221,102)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                      </g>
                      <g id="blocks-dispersed-g3" opacity="0.9">
                        <path id="blocks-dispersed-path3" d="M123.613000,123.523000L0,123.523000L0,216.016000L123.613000,216.016000L123.613000,123.523000Z" opacity="0.9" fill={`url(#blocks-dispersed-path3-fill-${unique})`} stroke="none" strokeWidth="1" />

                        <path id="blocks-dispersed-path4" d="M0,216.016000L0,123.523000L123.613000,123.523000L123.613000,216.016000L0,216.016000ZM3.090340,212.933000L3.090340,126.607000L120.523000,126.607000L120.523000,212.933000L3.090340,212.933000Z" opacity="0.9" clipRule="evenodd" fill="rgb(255,255,133)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                      </g>
                      <g id="blocks-dispersed-g4" opacity="0.9">
                        <path id="blocks-dispersed-path5" d="M154.879000,197.229000L154.879000,104.736000L123.976000,123.235000L123.976000,215.727000L154.879000,197.229000Z" transform="matrix(1 0 0 1 0 -0.12065000000001)" opacity="0.9" clipRule="evenodd" fill="rgb(36,4,78)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                      </g>
                    </g>
                    <g id="blocks-dispersed-g5" opacity="0.9">
                      <g id="blocks-dispersed-g6" opacity="0.9">
                        <g id="blocks-dispersed-g7" opacity="0.9">

                          <path id="blocks-dispersed-path6" d="M71.704900,109.434000L71.704900,97.102000C71.704900,95.400100,64.782500,94.018900,56.253200,94.018900C47.723900,94.018900,40.801500,95.400100,40.801500,97.102000L40.801500,109.434000C40.801500,107.732000,47.723900,106.351000,56.253200,106.351000C64.782500,106.351000,71.704900,107.732000,71.704900,109.434000ZM70.159700,97.493600L70.159700,106.447000C69.974300,106.379000,69.789000,106.314000,69.572700,106.253000C66.698700,105.396000,61.815800,104.810000,56.253200,104.810000C50.690600,104.810000,45.807700,105.396000,42.933700,106.253000C42.717300,106.314000,42.532100,106.379000,42.346700,106.447000L42.346700,97.493600C42.532100,97.385700,42.872100,97.203800,43.150200,97.099000C44.355400,96.645800,46.178500,96.275800,48.341800,96.007500C50.628600,95.723900,53.348300,95.560400,56.253200,95.560400C59.158100,95.560400,61.877800,95.723900,64.164600,96.007500C66.358800,96.275800,68.151000,96.645800,69.356200,97.099000C69.634300,97.203800,69.974300,97.385700,70.159700,97.493600Z" opacity="0.9" clipRule="evenodd" fill="rgb(7,4,254)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                        </g>
                        <g id="blocks-dispersed-g8" opacity="0.9">
                          <g id="blocks-dispersed-g9" opacity="0.75">
                            <g id="blocks-dispersed-g10" opacity="0.75">

                              <path id="blocks-dispersed-path7" d="M40.801500,96.860400C40.801500,95.158600,47.723900,93.777300,56.253200,93.777300C64.782500,93.777300,71.704900,95.158600,71.704900,96.860400L71.704900,109.193000L71.704900,109.162000C71.704900,109.171000,71.704900,109.184000,71.704900,109.193000C71.704900,110.895000,64.782500,112.276000,56.253200,112.276000C47.723900,112.276000,40.801500,110.895000,40.801500,109.193000L40.801500,96.860400ZM50.319600,106.344000C44.757000,106.806000,40.832400,107.904000,40.801500,109.184000C40.832400,107.910000,44.726400,106.819000,50.258100,106.353000L50.319600,106.344000ZM50.752300,106.310000C50.814100,106.307000,50.876100,106.301000,50.937900,106.298000L51.030700,106.292000C50.907100,106.298000,50.783100,106.307000,50.690400,106.316000L50.752300,106.310000Z" opacity="0.75" clipRule="evenodd" fill="rgb(255,221,102)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                            <g id="blocks-dispersed-g11" opacity="0.75">

                              <path id="blocks-dispersed-path8" d="M71.704900,96.909400L71.704900,109.242000C71.704900,110.944000,64.782500,112.325000,56.253200,112.325000C47.723900,112.325000,40.801500,110.944000,40.801500,109.242000L40.801500,96.909400C40.801500,98.611300,47.723900,99.992500,56.253200,99.992500C64.782500,99.992500,71.704900,98.611300,71.704900,96.909400Z" opacity="0.75" clipRule="evenodd" fill="url(#blocks-dispersed-path8-fill)" fillRule="evenodd" stroke="none" strokeWidth="1" />

                              <path id="blocks-dispersed-path9" d="M40.801500,96.909400C40.801500,98.611300,47.723900,99.992500,56.253200,99.992500C64.782500,99.992500,71.704900,98.611300,71.704900,96.909400L71.704900,109.242000C71.704900,110.944000,64.782500,112.325000,56.253200,112.325000C47.723900,112.325000,40.801500,110.944000,40.801500,109.242000L40.801500,96.909400ZM70.159700,99.896900L70.159700,108.850000C69.974300,108.958000,69.634300,109.140000,69.356200,109.245000C68.151000,109.698000,66.358800,110.068000,64.164600,110.336000C61.877800,110.620000,59.158100,110.783000,56.253200,110.783000C53.348300,110.783000,50.628600,110.620000,48.341800,110.336000C46.178500,110.068000,44.355400,109.698000,43.150200,109.245000C42.872100,109.140000,42.532100,108.958000,42.346700,108.850000L42.346700,99.896900C42.532100,99.964700,42.717300,100.029000,42.933700,100.091000C45.807700,100.948000,50.690600,101.534000,56.253200,101.534000C61.815800,101.534000,66.698700,100.948000,69.572700,100.091000C69.789000,100.029000,69.974300,99.964700,70.159700,99.896900Z" opacity="0.75" clipRule="evenodd" fill="rgb(255,255,133)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g id="blocks-dispersed-g12" opacity="0.9">
                        <g id="blocks-dispersed-g13" opacity="0.9">

                          <path id="blocks-dispersed-path10" d="M54.442900,118.829000L54.442900,106.496000C54.442900,104.794000,47.520600,103.413000,38.991200,103.413000C30.461900,103.413000,23.539600,104.794000,23.539600,106.496000L23.539600,118.829000C23.539600,117.127000,30.461900,115.746000,38.991200,115.746000C47.520600,115.746000,54.442900,117.127000,54.442900,118.829000ZM52.897700,106.888000L52.897700,115.841000C52.712300,115.773000,52.527100,115.709000,52.310800,115.647000C49.436700,114.790000,44.553800,114.204000,38.991200,114.204000C33.428600,114.204000,28.545700,114.790000,25.671700,115.647000C25.455400,115.709000,25.270100,115.773000,25.084700,115.841000L25.084700,106.888000C25.270100,106.780000,25.610100,106.598000,25.888200,106.493000C27.093500,106.040000,28.916600,105.670000,31.079800,105.402000C33.366600,105.118000,36.086300,104.955000,38.991200,104.955000C41.896100,104.955000,44.615800,105.118000,46.902700,105.402000C49.096800,105.670000,50.889000,106.040000,52.094200,106.493000C52.372400,106.598000,52.712300,106.780000,52.897700,106.888000Z" opacity="0.9" clipRule="evenodd" fill="rgb(7,4,254)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                        </g>
                        <g id="blocks-dispersed-g14" opacity="0.9">
                          <g id="blocks-dispersed-g15" opacity="0.75">
                            <g id="blocks-dispersed-g16" opacity="0.75">

                              <path id="blocks-dispersed-path11" d="M23.539600,106.255000C23.539600,104.553000,30.461900,103.172000,38.991200,103.172000C47.520600,103.172000,54.442900,104.553000,54.442900,106.255000L54.442900,118.587000L54.442900,118.556000C54.442900,118.566000,54.442900,118.578000,54.442900,118.587000C54.442900,120.289000,47.520600,121.670000,38.991200,121.670000C30.461900,121.670000,23.539600,120.289000,23.539600,118.587000L23.539600,106.255000ZM33.057600,115.738000C27.495000,116.201000,23.570500,117.299000,23.539600,118.578000C23.570500,117.305000,27.464500,116.213000,32.996200,115.748000L33.057600,115.738000ZM33.490300,115.705000C33.552100,115.702000,33.614100,115.695000,33.675900,115.692000L33.768700,115.686000C33.645100,115.692000,33.521200,115.702000,33.428500,115.711000L33.490300,115.705000Z" opacity="0.75" clipRule="evenodd" fill="rgb(255,221,102)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                            <g id="blocks-dispersed-g17" opacity="0.75">

                              <path id="blocks-dispersed-path12" d="M54.442900,106.304000L54.442900,118.636000C54.442900,120.338000,47.520600,121.719000,38.991200,121.719000C30.461900,121.719000,23.539600,120.338000,23.539600,118.636000L23.539600,106.304000C23.539600,108.006000,30.461900,109.387000,38.991200,109.387000C47.520600,109.387000,54.442900,108.006000,54.442900,106.304000Z" opacity="0.75" clipRule="evenodd" fill={`url(#blocks-dispersed-path12-fill-${unique})`} fillRule="evenodd" stroke="none" strokeWidth="1" />

                              <path id="blocks-dispersed-path13" d="M23.539600,106.304000C23.539600,108.006000,30.461900,109.387000,38.991200,109.387000C47.520600,109.387000,54.442900,108.006000,54.442900,106.304000L54.442900,118.636000C54.442900,120.338000,47.520600,121.719000,38.991200,121.719000C30.461900,121.719000,23.539600,120.338000,23.539600,118.636000L23.539600,106.304000ZM52.897700,109.291000L52.897700,118.244000C52.712300,118.352000,52.372400,118.534000,52.094200,118.639000C50.889000,119.092000,49.096800,119.462000,46.902700,119.730000C44.615800,120.014000,41.896100,120.178000,38.991200,120.178000C36.086300,120.178000,33.366600,120.014000,31.079800,119.730000C28.916600,119.462000,27.093500,119.092000,25.888200,118.639000C25.610100,118.534000,25.270100,118.352000,25.084700,118.244000L25.084700,109.291000C25.270100,109.359000,25.455400,109.424000,25.671700,109.485000C28.545700,110.342000,33.428600,110.928000,38.991200,110.928000C44.553800,110.928000,49.436700,110.342000,52.310800,109.485000C52.527100,109.424000,52.712300,109.359000,52.897700,109.291000Z" opacity="0.75" clipRule="evenodd" fill="rgb(255,255,133)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g id="blocks-dispersed-g18" opacity="0.9">
                        <g id="blocks-dispersed-g19" opacity="0.9">

                          <path id="blocks-dispersed-path14" d="M132.912000,109.434000L132.912000,97.102000C132.912000,95.400100,125.990000,94.018900,117.461000,94.018900C108.931000,94.018900,102.009000,95.400100,102.009000,97.102000L102.009000,109.434000C102.009000,107.732000,108.931000,106.351000,117.461000,106.351000C125.990000,106.351000,132.912000,107.732000,132.912000,109.434000ZM131.367000,97.493600L131.367000,106.447000C131.182000,106.379000,130.997000,106.314000,130.780000,106.253000C127.906000,105.396000,123.023000,104.810000,117.461000,104.810000C111.898000,104.810000,107.015000,105.396000,104.141000,106.253000C103.925000,106.314000,103.740000,106.379000,103.554000,106.447000L103.554000,97.493600C103.740000,97.385700,104.080000,97.203800,104.358000,97.099000C105.563000,96.645800,107.386000,96.275800,109.549000,96.007500C111.836000,95.723900,114.556000,95.560400,117.461000,95.560400C120.366000,95.560400,123.085000,95.723900,125.372000,96.007500C127.566000,96.275800,129.358000,96.645800,130.564000,97.099000C130.842000,97.203800,131.182000,97.385700,131.367000,97.493600Z" opacity="0.9" clipRule="evenodd" fill="rgb(7,4,254)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                        </g>
                        <g id="blocks-dispersed-g20" opacity="0.9">
                          <g id="blocks-dispersed-g21" opacity="0.75">
                            <g id="blocks-dispersed-g22" opacity="0.75">

                              <path id="blocks-dispersed-path15" d="M102.011000,96.860400C102.011000,95.158600,108.933000,93.777300,117.462000,93.777300C125.992000,93.777300,132.914000,95.158600,132.914000,96.860400L132.914000,109.193000L132.914000,109.162000C132.914000,109.171000,132.914000,109.184000,132.914000,109.193000C132.914000,110.895000,125.992000,112.276000,117.462000,112.276000C108.933000,112.276000,102.011000,110.895000,102.011000,109.193000L102.011000,96.860400ZM111.529000,106.344000C105.966000,106.806000,102.042000,107.904000,102.011000,109.184000C102.042000,107.910000,105.936000,106.819000,111.467000,106.353000L111.529000,106.344000ZM111.962000,106.310000C112.023000,106.307000,112.085000,106.301000,112.147000,106.298000L112.240000,106.292000C112.116000,106.298000,111.992000,106.307000,111.900000,106.316000L111.962000,106.310000Z" opacity="0.75" clipRule="evenodd" fill="rgb(255,221,102)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                            <g id="blocks-dispersed-g23" opacity="0.75">

                              <path id="blocks-dispersed-path16" d="M132.916000,96.907700L132.916000,109.240000C132.916000,110.942000,125.994000,112.323000,117.464000,112.323000C108.935000,112.323000,102.013000,110.942000,102.013000,109.240000L102.013000,96.907700C102.013000,98.609600,108.935000,99.990800,117.464000,99.990800C125.994000,99.990800,132.916000,98.609600,132.916000,96.907700Z" opacity="0.75" clipRule="evenodd" fill={`url(#blocks-dispersed-path16-fill-${unique})`} fillRule="evenodd" stroke="none" strokeWidth="1" />

                              <path id="blocks-dispersed-path17" d="M102.013000,96.907700C102.013000,98.609600,108.935000,99.990800,117.464000,99.990800C125.994000,99.990800,132.916000,98.609600,132.916000,96.907700L132.916000,109.240000C132.916000,110.942000,125.994000,112.323000,117.464000,112.323000C108.935000,112.323000,102.013000,110.942000,102.013000,109.240000L102.013000,96.907700ZM131.371000,99.895200L131.371000,108.848000C131.185000,108.956000,130.845000,109.138000,130.567000,109.243000C129.362000,109.696000,127.570000,110.066000,125.376000,110.334000C123.089000,110.618000,120.369000,110.782000,117.464000,110.782000C114.559000,110.782000,111.840000,110.618000,109.553000,110.334000C107.390000,110.066000,105.567000,109.696000,104.361000,109.243000C104.083000,109.138000,103.743000,108.956000,103.558000,108.848000L103.558000,99.895200C103.743000,99.963000,103.929000,100.028000,104.145000,100.089000C107.019000,100.947000,111.902000,101.532000,117.464000,101.532000C123.027000,101.532000,127.910000,100.947000,130.784000,100.089000C131,100.028000,131.185000,99.963000,131.371000,99.895200Z" opacity="0.75" clipRule="evenodd" fill="rgb(255,255,133)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g id="blocks-dispersed-g24" opacity="0.9">
                        <g id="blocks-dispersed-g25" opacity="0.9">
                          <path id="blocks-dispersed-path18" d="M117.211000,118.830000L117.211000,106.497000C117.211000,104.796000,110.289000,103.414000,101.759000,103.414000C93.230000,103.414000,86.307600,104.796000,86.307600,106.497000L86.307600,118.830000C86.307600,117.128000,93.230000,115.747000,101.759000,115.747000C110.289000,115.747000,117.211000,117.128000,117.211000,118.830000ZM115.666000,106.889000L115.666000,115.842000C115.480000,115.775000,115.295000,115.710000,115.079000,115.648000C112.205000,114.791000,107.322000,114.205000,101.759000,114.205000C96.196700,114.205000,91.313800,114.791000,88.439800,115.648000C88.223400,115.710000,88.038200,115.775000,87.852800,115.842000L87.852800,106.889000C88.038200,106.781000,88.378200,106.599000,88.656300,106.494000C89.861500,106.041000,91.684600,105.671000,93.847900,105.403000C96.134700,105.119000,98.854400,104.956000,101.759000,104.956000C104.664000,104.956000,107.384000,105.119000,109.671000,105.403000C111.865000,105.671000,113.657000,106.041000,114.862000,106.494000C115.140000,106.599000,115.480000,106.781000,115.666000,106.889000Z" opacity="0.9" clipRule="evenodd" fill="rgb(7,4,254)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                        </g>
                        <g id="blocks-dispersed-g26" opacity="0.9">
                          <g id="blocks-dispersed-g27" opacity="0.75">
                            <g id="blocks-dispersed-g28" opacity="0.75">
                              <path id="blocks-dispersed-path19" d="M86.302700,106.256000C86.302700,104.555000,93.225100,103.173000,101.754000,103.173000C110.284000,103.173000,117.206000,104.555000,117.206000,106.256000L117.206000,118.589000L117.206000,118.558000C117.206000,118.567000,117.206000,118.580000,117.206000,118.589000C117.206000,120.291000,110.284000,121.672000,101.754000,121.672000C93.225100,121.672000,86.302700,120.291000,86.302700,118.589000L86.302700,106.256000ZM95.820800,115.740000C90.258200,116.202000,86.333600,117.300000,86.302700,118.580000C86.333600,117.306000,90.227600,116.215000,95.759300,115.749000L95.820800,115.740000ZM96.253500,115.706000C96.315300,115.703000,96.377300,115.697000,96.439100,115.694000L96.531900,115.688000C96.408300,115.694000,96.284400,115.703000,96.191700,115.712000L96.253500,115.706000Z" opacity="0.75" clipRule="evenodd" fill="rgb(255,221,102)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                            <g id="blocks-dispersed-g29" opacity="0.75">
                              <path id="blocks-dispersed-path20" d="M117.206000,106.305000L117.206000,118.637000C117.206000,120.339000,110.284000,121.720000,101.754000,121.720000C93.225100,121.720000,86.302700,120.339000,86.302700,118.637000L86.302700,106.305000C86.302700,108.007000,93.225100,109.388000,101.754000,109.388000C110.284000,109.388000,117.206000,108.007000,117.206000,106.305000Z" opacity="0.75" clipRule="evenodd" fill={`url(#blocks-dispersed-path20-fill-${unique})`} fillRule="evenodd" stroke="none" strokeWidth="1" />
                              <path id="blocks-dispersed-path21" d="M86.302700,106.305000C86.302700,108.007000,93.225100,109.388000,101.754000,109.388000C110.284000,109.388000,117.206000,108.007000,117.206000,106.305000L117.206000,118.637000C117.206000,120.339000,110.284000,121.720000,101.754000,121.720000C93.225100,121.720000,86.302700,120.339000,86.302700,118.637000L86.302700,106.305000ZM115.661000,109.292000L115.661000,118.246000C115.475000,118.354000,115.136000,118.535000,114.857000,118.640000C113.652000,119.094000,111.860000,119.463000,109.666000,119.732000C107.379000,120.015000,104.659000,120.179000,101.754000,120.179000C98.849500,120.179000,96.129800,120.015000,93.843000,119.732000C91.679700,119.463000,89.856600,119.094000,88.651400,118.640000C88.373300,118.535000,88.033300,118.354000,87.847900,118.246000L87.847900,109.292000C88.033300,109.360000,88.218600,109.425000,88.434900,109.487000C91.308900,110.344000,96.191800,110.930000,101.754000,110.930000C107.317000,110.930000,112.200000,110.344000,115.074000,109.487000C115.290000,109.425000,115.475000,109.360000,115.661000,109.292000Z" opacity="0.75" clipRule="evenodd" fill="rgb(255,255,133)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="blocks-dispersed-blue-cube_to" transform="translate(283.017502,13.971500)" ref={blueCube}>
                  <g id="blocks-dispersed-blue-cube" transform="translate(-283.017502,-13.971500)">
                    <path id="blocks-dispersed-path22" d="M235.388000,103.451000L235.388000,10.959000L359.002000,10.959000L359.002000,103.451000L235.388000,103.451000ZM238.479000,100.369000L238.479000,14.041200L355.912000,14.041200L355.912000,100.369000L238.479000,100.369000Z" clipRule="evenodd" fill="rgb(7,4,254)" fillOpacity="0.5" fillRule="evenodd" stroke="none" strokeWidth="1" />
                    <g id="blocks-dispersed-g30" opacity="0.9">
                      <g id="blocks-dispersed-g31" opacity="0.9">
                        <path id="blocks-dispersed-path23" d="M204,121.951000L204,29.458000L234.903000,10.959500L358.517000,10.959500L358.517000,103.452000L327.613000,121.951000L204,121.951000Z" opacity="0.9" clipRule="evenodd" fill="rgb(200,232,255)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                      </g>
                      <g id="blocks-dispersed-g32" opacity="0.9">
                        <path id="blocks-dispersed-path24" d="M327.613000,29.746100L204,29.746100L204,122.239000L327.613000,122.239000L327.613000,29.746100Z" opacity="0.9" fill={`url(#blocks-dispersed-path24-fill-${unique})`} stroke="none" strokeWidth="1" />
                        <path id="blocks-dispersed-path25" d="M204,122.239000L204,29.746100L327.613000,29.746100L327.613000,122.239000L204,122.239000ZM207.090000,119.156000L207.090000,32.829200L324.523000,32.829200L324.523000,119.156000L207.090000,119.156000Z" opacity="0.9" clipRule="evenodd" fill="rgb(16,254,254)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                      </g>
                      <g id="blocks-dispersed-g33" opacity="0.9">
                        <path id="blocks-dispersed-path26" d="M358.879000,103.451000L358.879000,10.958700L327.976000,29.457300L327.976000,121.950000L358.879000,103.451000Z" opacity="0.9" clipRule="evenodd" fill="rgb(36,4,78)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                      </g>
                    </g>
                    <g id="blocks-dispersed-g34" opacity="0.9">
                      <g id="blocks-dispersed-g35" opacity="0.9">
                        <g id="blocks-dispersed-g36" opacity="0.9">
                          <path id="blocks-dispersed-path27" d="M275.709000,15.656700L275.709000,3.324390C275.709000,1.622530,268.786000,0.241307,260.257000,0.241307C251.728000,0.241307,244.805000,1.622530,244.805000,3.324390L244.805000,15.656700C244.805000,13.954900,251.728000,12.573700,260.257000,12.573700C268.786000,12.573700,275.709000,13.954900,275.709000,15.656700ZM274.163000,3.715990L274.163000,12.669200C273.978000,12.601400,273.793000,12.536700,273.576000,12.475000C270.702000,11.617900,265.819000,11.032100,260.257000,11.032100C254.694000,11.032100,249.811000,11.617900,246.937000,12.475000C246.721000,12.536700,246.536000,12.601400,246.350000,12.669200L246.350000,3.715990C246.536000,3.608080,246.876000,3.426210,247.154000,3.321380C248.359000,2.868170,250.182000,2.498190,252.345000,2.229960C254.632000,1.946310,257.352000,1.782850,260.257000,1.782850C263.162000,1.782850,265.881000,1.946310,268.168000,2.229960C270.362000,2.498190,272.155000,2.868170,273.360000,3.321380C273.638000,3.426210,273.978000,3.608080,274.163000,3.715990Z" opacity="0.9" clipRule="evenodd" fill="rgb(7,4,254)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                        </g>
                        <g id="blocks-dispersed-g37" opacity="0.9">
                          <g id="blocks-dispersed-g38" opacity="0.75">
                            <g id="blocks-dispersed-g39" opacity="0.75">
                              <path id="blocks-dispersed-path28" d="M244.805000,3.084800C244.805000,1.382930,251.728000,0.001709,260.257000,0.001709C268.786000,0.001709,275.709000,1.382930,275.709000,3.084800L275.709000,15.417100L275.709000,15.386300C275.709000,15.395500,275.709000,15.407900,275.709000,15.417100C275.709000,17.119000,268.786000,18.500200,260.257000,18.500200C251.728000,18.500200,244.805000,17.119000,244.805000,15.417100L244.805000,3.084800ZM254.324000,12.568300C248.761000,13.030800,244.836000,14.128400,244.805000,15.407900C244.836000,14.134600,248.730000,13.043100,254.262000,12.577600L254.324000,12.568300ZM254.756000,12.534500C254.818000,12.531400,254.880000,12.525100,254.942000,12.522000L255.035000,12.516000C254.911000,12.522200,254.787000,12.531400,254.694000,12.540700L254.756000,12.534500Z" opacity="0.75" clipRule="evenodd" fill="rgb(200,232,255)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                            <g id="blocks-dispersed-g40" opacity="0.75">
                              <path id="blocks-dispersed-path29" d="M275.709000,3.131840L275.709000,15.464200C275.709000,17.166000,268.786000,18.547300,260.257000,18.547300C251.728000,18.547300,244.805000,17.166000,244.805000,15.464200L244.805000,3.131840C244.805000,4.833700,251.728000,6.214920,260.257000,6.214920C268.786000,6.214920,275.709000,4.833700,275.709000,3.131840Z" opacity="0.75" clipRule="evenodd" fill={`url(#blocks-dispersed-path29-fill-${unique})`} fillRule="evenodd" stroke="none" strokeWidth="1" />
                              <path id="blocks-dispersed-path30" d="M244.805000,3.131840C244.805000,4.833700,251.728000,6.214920,260.257000,6.214920C268.786000,6.214920,275.709000,4.833700,275.709000,3.131840L275.709000,15.464200C275.709000,17.166000,268.786000,18.547300,260.257000,18.547300C251.728000,18.547300,244.805000,17.166000,244.805000,15.464200L244.805000,3.131840ZM274.163000,6.119330L274.163000,15.072600C273.978000,15.180500,273.638000,15.362400,273.360000,15.467200C272.155000,15.920400,270.362000,16.290400,268.168000,16.558600C265.881000,16.842300,263.162000,17.005700,260.257000,17.005700C257.352000,17.005700,254.632000,16.842300,252.345000,16.558600C250.182000,16.290400,248.359000,15.920400,247.154000,15.467200C246.876000,15.362400,246.536000,15.180500,246.350000,15.072600L246.350000,6.119330C246.536000,6.187160,246.721000,6.251860,246.937000,6.313530C249.811000,7.170620,254.694000,7.756460,260.257000,7.756460C265.819000,7.756460,270.702000,7.170620,273.576000,6.313530C273.793000,6.251860,273.978000,6.187160,274.163000,6.119330Z" opacity="0.75" clipRule="evenodd" fill="rgb(16,254,254)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g id="blocks-dispersed-g41" opacity="0.9">
                        <g id="blocks-dispersed-g42" opacity="0.9">
                          <path id="blocks-dispersed-path31" d="M260.019000,25.052700L260.019000,12.720400C260.019000,11.018500,253.097000,9.637310,244.568000,9.637310C236.038000,9.637310,229.116000,11.018500,229.116000,12.720400L229.116000,25.052700C229.116000,23.350900,236.038000,21.969600,244.568000,21.969600C253.097000,21.969600,260.019000,23.350900,260.019000,25.052700ZM258.474000,13.112000L258.474000,22.065200C258.289000,21.997400,258.103000,21.932700,257.887000,21.871000C255.013000,21.013900,250.130000,20.428100,244.568000,20.428100C239.005000,20.428100,234.122000,21.013900,231.248000,21.871000C231.032000,21.932700,230.847000,21.997400,230.661000,22.065200L230.661000,13.112000C230.847000,13.004100,231.187000,12.822200,231.465000,12.717400C232.670000,12.264200,234.493000,11.894200,236.656000,11.626000C238.943000,11.342300,241.663000,11.178800,244.568000,11.178800C247.473000,11.178800,250.192000,11.342300,252.479000,11.626000C254.673000,11.894200,256.465000,12.264200,257.671000,12.717400C257.949000,12.822200,258.289000,13.004100,258.474000,13.112000Z" opacity="0.9" clipRule="evenodd" fill="rgb(7,4,254)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                        </g>
                        <g id="blocks-dispersed-g43" opacity="0.9">
                          <g id="blocks-dispersed-g44" opacity="0.75">
                            <g id="blocks-dispersed-g45" opacity="0.75">
                              <path id="blocks-dispersed-path32" d="M229.119000,12.478800C229.119000,10.777000,236.042000,9.395750,244.571000,9.395750C253.100000,9.395750,260.023000,10.777000,260.023000,12.478800L260.023000,24.811200L260.023000,24.780300C260.023000,24.789600,260.023000,24.801900,260.023000,24.811200C260.023000,26.513100,253.100000,27.894300,244.571000,27.894300C236.042000,27.894300,229.119000,26.513100,229.119000,24.811200L229.119000,12.478800ZM238.637000,21.962400C233.075000,22.424800,229.150000,23.522500,229.119000,24.802000C229.150000,23.528700,233.044000,22.437100,238.576000,21.971600L238.637000,21.962400ZM239.070000,21.928500C239.132000,21.925400,239.194000,21.919200,239.256000,21.916100L239.349000,21.910100C239.225000,21.916200,239.101000,21.925500,239.008000,21.934700L239.070000,21.928500Z" opacity="0.75" clipRule="evenodd" fill="rgb(200,232,255)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                            <g id="blocks-dispersed-g46" opacity="0.75">
                              <path id="blocks-dispersed-path33" d="M260.023000,12.527300L260.023000,24.859700C260.023000,26.561600,253.100000,27.942800,244.571000,27.942800C236.042000,27.942800,229.119000,26.561600,229.119000,24.859700L229.119000,12.527300C229.119000,14.229200,236.042000,15.610400,244.571000,15.610400C253.100000,15.610400,260.023000,14.229200,260.023000,12.527300Z" opacity="0.75" clipRule="evenodd" fill={`url(#blocks-dispersed-path33-fill-${unique})`} fillRule="evenodd" stroke="none" strokeWidth="1" />
                              <path id="blocks-dispersed-path34" d="M229.119000,12.527300C229.119000,14.229200,236.042000,15.610400,244.571000,15.610400C253.100000,15.610400,260.023000,14.229200,260.023000,12.527300L260.023000,24.859700C260.023000,26.561600,253.100000,27.942800,244.571000,27.942800C236.042000,27.942800,229.119000,26.561600,229.119000,24.859700L229.119000,12.527300ZM258.478000,15.514800L258.478000,24.468100C258.292000,24.576000,257.952000,24.757900,257.674000,24.862700C256.469000,25.315900,254.677000,25.685900,252.482000,25.954100C250.196000,26.237800,247.476000,26.401200,244.571000,26.401200C241.666000,26.401200,238.946000,26.237800,236.660000,25.954100C234.496000,25.685900,232.673000,25.315900,231.468000,24.862700C231.190000,24.757900,230.850000,24.576000,230.665000,24.468100L230.665000,15.514800C230.850000,15.582700,231.035000,15.647400,231.252000,15.709000C234.126000,16.566100,239.008000,17.152000,244.571000,17.152000C250.134000,17.152000,255.017000,16.566100,257.891000,15.709000C258.107000,15.647400,258.292000,15.582700,258.478000,15.514800Z" opacity="0.75" clipRule="evenodd" fill="rgb(16,254,254)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g id="blocks-dispersed-g47" opacity="0.9">
                        <g id="blocks-dispersed-g48" opacity="0.9">
                          <path id="blocks-dispersed-path35" d="M336.916000,15.657000L336.916000,3.324640C336.916000,1.622780,329.993000,0.241553,321.464000,0.241553C312.935000,0.241553,306.012000,1.622780,306.012000,3.324640L306.012000,15.657000C306.012000,13.955100,312.935000,12.573900,321.464000,12.573900C329.993000,12.573900,336.916000,13.955100,336.916000,15.657000ZM335.371000,3.716240L335.371000,12.669500C335.185000,12.601700,335,12.537000,334.784000,12.475300C331.910000,11.618200,327.027000,11.032400,321.464000,11.032400C315.902000,11.032400,311.019000,11.618200,308.145000,12.475300C307.928000,12.537000,307.743000,12.601700,307.558000,12.669500L307.558000,3.716240C307.743000,3.608330,308.083000,3.426450,308.361000,3.321630C309.566000,2.868410,311.389000,2.498430,313.553000,2.230200C315.840000,1.946560,318.559000,1.783100,321.464000,1.783100C324.369000,1.783100,327.089000,1.946560,329.376000,2.230200C331.570000,2.498430,333.362000,2.868410,334.567000,3.321630C334.845000,3.426450,335.185000,3.608330,335.371000,3.716240Z" opacity="0.9" clipRule="evenodd" fill="rgb(7,4,254)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                        </g>
                        <g id="blocks-dispersed-g49" opacity="0.9">
                          <g id="blocks-dispersed-g50" opacity="0.75">
                            <g id="blocks-dispersed-g51" opacity="0.75">
                              <path id="blocks-dispersed-path36" d="M306.014000,3.083090C306.014000,1.381220,312.937000,0,321.466000,0C329.995000,0,336.918000,1.381220,336.918000,3.083090L336.918000,15.415400L336.918000,15.384600C336.918000,15.393800,336.918000,15.406200,336.918000,15.415400C336.918000,17.117300,329.995000,18.498500,321.466000,18.498500C312.937000,18.498500,306.014000,17.117300,306.014000,15.415400L306.014000,3.083090ZM315.533000,12.566600C309.970000,13.029100,306.045000,14.126700,306.014000,15.406200C306.045000,14.132900,309.939000,13.041400,315.471000,12.575800L315.533000,12.566600ZM315.965000,12.532800C316.027000,12.529700,316.089000,12.523400,316.151000,12.520300L316.244000,12.514300C316.120000,12.520500,315.996000,12.529700,315.903000,12.539000L315.965000,12.532800Z" opacity="0.75" clipRule="evenodd" fill="rgb(200,232,255)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                            <g id="blocks-dispersed-g52" opacity="0.75">
                              <path id="blocks-dispersed-path37" d="M336.919000,3.130370L336.919000,15.462700C336.919000,17.164600,329.997000,18.545800,321.468000,18.545800C312.938000,18.545800,306.016000,17.164600,306.016000,15.462700L306.016000,3.130370C306.016000,4.832230,312.938000,6.213460,321.468000,6.213460C329.997000,6.213460,336.919000,4.832230,336.919000,3.130370Z" opacity="0.75" clipRule="evenodd" fill={`url(#blocks-dispersed-path37-fill-${unique})`} fillRule="evenodd" stroke="none" strokeWidth="1" />
                              <path id="blocks-dispersed-path38" d="M306.016000,3.130370C306.016000,4.832230,312.938000,6.213460,321.468000,6.213460C329.997000,6.213460,336.919000,4.832230,336.919000,3.130370L336.919000,15.462700C336.919000,17.164600,329.997000,18.545800,321.468000,18.545800C312.938000,18.545800,306.016000,17.164600,306.016000,15.462700L306.016000,3.130370ZM335.374000,6.117860L335.374000,15.071100C335.189000,15.179000,334.849000,15.360900,334.571000,15.465700C333.366000,15.918900,331.573000,16.288900,329.379000,16.557100C327.092000,16.840800,324.373000,17.004300,321.468000,17.004300C318.563000,17.004300,315.843000,16.840800,313.556000,16.557100C311.393000,16.288900,309.570000,15.918900,308.365000,15.465700C308.087000,15.360900,307.747000,15.179000,307.561000,15.071100L307.561000,6.117860C307.747000,6.185690,307.932000,6.250400,308.148000,6.312060C311.022000,7.169160,315.905000,7.755000,321.468000,7.755000C327.030000,7.755000,331.913000,7.169160,334.787000,6.312060C335.004000,6.250400,335.189000,6.185690,335.374000,6.117860Z" opacity="0.75" clipRule="evenodd" fill="rgb(16,254,254)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g id="blocks-dispersed-g53" opacity="0.9">
                        <g id="blocks-dispersed-g54" opacity="0.9">
                          <path id="blocks-dispersed-path39" d="M321.214000,25.052500L321.214000,12.720100C321.214000,11.018300,314.292000,9.637060,305.762000,9.637060C297.233000,9.637060,290.311000,11.018300,290.311000,12.720100L290.311000,25.052500C290.311000,23.350600,297.233000,21.969400,305.762000,21.969400C314.292000,21.969400,321.214000,23.350600,321.214000,25.052500ZM319.669000,13.111700L319.669000,22.065000C319.484000,21.997200,319.298000,21.932500,319.082000,21.870800C316.208000,21.013700,311.325000,20.427900,305.762000,20.427900C300.200000,20.427900,295.317000,21.013700,292.443000,21.870800C292.227000,21.932500,292.041000,21.997200,291.856000,22.065000L291.856000,13.111700C292.041000,13.003800,292.381000,12.822000,292.659000,12.717100C293.865000,12.263900,295.688000,11.893900,297.851000,11.625700C300.138000,11.342100,302.858000,11.178600,305.762000,11.178600C308.667000,11.178600,311.387000,11.342100,313.674000,11.625700C315.868000,11.893900,317.660000,12.263900,318.865000,12.717100C319.144000,12.822000,319.484000,13.003800,319.669000,13.111700Z" opacity="0.9" clipRule="evenodd" fill="rgb(7,4,254)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                        </g>
                        <g id="blocks-dispersed-g55" opacity="0.9">
                          <g id="blocks-dispersed-g56" opacity="0.75">
                            <g id="blocks-dispersed-g57" opacity="0.75">
                              <path id="blocks-dispersed-path40" d="M290.306000,12.479100C290.306000,10.777200,297.229000,9.396000,305.758000,9.396000C314.287000,9.396000,321.210000,10.777200,321.210000,12.479100L321.210000,24.811400L321.210000,24.780600C321.210000,24.789800,321.210000,24.802200,321.210000,24.811400C321.210000,26.513300,314.287000,27.894500,305.758000,27.894500C297.229000,27.894500,290.306000,26.513300,290.306000,24.811400L290.306000,12.479100ZM299.824000,21.962600C294.262000,22.425100,290.337000,23.522700,290.306000,24.802200C290.337000,23.528900,294.231000,22.437400,299.763000,21.971800L299.824000,21.962600ZM300.257000,21.928800C300.319000,21.925700,300.381000,21.919400,300.443000,21.916300L300.536000,21.910300C300.412000,21.916500,300.288000,21.925700,300.195000,21.935000L300.257000,21.928800Z" opacity="0.75" clipRule="evenodd" fill="rgb(200,232,255)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                            <g id="blocks-dispersed-g58" opacity="0.75">
                              <path id="blocks-dispersed-path41" d="M321.210000,12.527600L321.210000,24.859900C321.210000,26.561800,314.287000,27.943000,305.758000,27.943000C297.229000,27.943000,290.306000,26.561800,290.306000,24.859900L290.306000,12.527600C290.306000,14.229500,297.229000,15.610700,305.758000,15.610700C314.287000,15.610700,321.210000,14.229500,321.210000,12.527600Z" opacity="0.75" clipRule="evenodd" fill={`url(#blocks-dispersed-path41-fill-${unique})`} fillRule="evenodd" stroke="none" strokeWidth="1" />
                              <path id="blocks-dispersed-path42" d="M290.306000,12.527600C290.306000,14.229500,297.229000,15.610700,305.758000,15.610700C314.287000,15.610700,321.210000,14.229500,321.210000,12.527600L321.210000,24.859900C321.210000,26.561800,314.287000,27.943000,305.758000,27.943000C297.229000,27.943000,290.306000,26.561800,290.306000,24.859900L290.306000,12.527600ZM319.665000,15.515100L319.665000,24.468300C319.479000,24.576200,319.139000,24.758100,318.861000,24.862900C317.656000,25.316200,315.864000,25.686100,313.670000,25.954400C311.383000,26.238000,308.663000,26.401500,305.758000,26.401500C302.853000,26.401500,300.133000,26.238000,297.847000,25.954400C295.683000,25.686100,293.860000,25.316200,292.655000,24.862900C292.377000,24.758100,292.037000,24.576200,291.852000,24.468300L291.852000,15.515100C292.037000,15.582900,292.222000,15.647600,292.439000,15.709300C295.313000,16.566400,300.195000,17.152200,305.758000,17.152200C311.321000,17.152200,316.204000,16.566400,319.078000,15.709300C319.294000,15.647600,319.479000,15.582900,319.665000,15.515100Z" opacity="0.75" clipRule="evenodd" fill="rgb(16,254,254)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="blocks-dispersed-pink-cube_to" transform="translate(487.017990,115.733501)" ref={pinkCube}>
                  <g id="blocks-dispersed-pink-cube" transform="translate(-487.017990,-115.733501)">
                    <path id="blocks-dispersed-path43" d="M439.388000,205.214000L439.388000,112.721000L563.002000,112.721000L563.002000,205.214000L439.388000,205.214000ZM442.479000,202.132000L442.479000,115.804000L559.911000,115.804000L559.911000,202.132000L442.479000,202.132000Z" clipRule="evenodd" fill="rgb(7,4,254)" fillOpacity="0.5" fillRule="evenodd" stroke="none" strokeWidth="1" />
                    <g id="blocks-dispersed-g59" opacity="0.9">
                      <g id="blocks-dispersed-g60" opacity="0.9">
                        <path id="blocks-dispersed-path44" d="M408,223.712000L408,131.220000L438.903000,112.721000L562.517000,112.721000L562.517000,205.214000L531.613000,223.712000L408,223.712000Z" opacity="0.9" clipRule="evenodd" fill="rgb(235,141,208)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                      </g>
                      <g id="blocks-dispersed-g61" opacity="0.9">
                        <path id="blocks-dispersed-path45" d="M531.613000,131.509000L408,131.509000L408,224.001000L531.613000,224.001000L531.613000,131.509000Z" transform="matrix(1 0 0 1 0.36300000000000 0)" opacity="0.9" fill={`url(#blocks-dispersed-path45-fill-${unique})`} stroke="none" strokeWidth="1" />
                        <path id="blocks-dispersed-path46" d="M408,224.001000L408,131.509000L531.613000,131.509000L531.613000,224.001000L408,224.001000ZM411.090000,220.918000L411.090000,134.592000L528.523000,134.592000L528.523000,220.918000L411.090000,220.918000Z" opacity="0.9" clipRule="evenodd" fill="rgb(255,200,255)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                      </g>
                      <g id="blocks-dispersed-g62" opacity="0.9">
                        <path id="blocks-dispersed-path47" d="M562.879000,205.214000L562.879000,112.721000L531.976000,131.220000L531.976000,223.712000L562.879000,205.214000Z" opacity="0.9" clipRule="evenodd" fill="rgb(36,4,78)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                      </g>
                    </g>
                    <g id="blocks-dispersed-g63" opacity="0.9">
                      <g id="blocks-dispersed-g64" opacity="0.9">
                        <g id="blocks-dispersed-g65" opacity="0.9">
                          <path id="blocks-dispersed-path48" d="M479.709000,117.419000L479.709000,105.086000C479.709000,103.384000,472.786000,102.003000,464.257000,102.003000C455.728000,102.003000,448.805000,103.384000,448.805000,105.086000L448.805000,117.419000C448.805000,115.717000,455.728000,114.336000,464.257000,114.336000C472.786000,114.336000,479.709000,115.717000,479.709000,117.419000ZM478.164000,105.478000L478.164000,114.431000C477.978000,114.363000,477.793000,114.299000,477.577000,114.237000C474.703000,113.380000,469.820000,112.794000,464.257000,112.794000C458.694000,112.794000,453.812000,113.380000,450.938000,114.237000C450.721000,114.299000,450.536000,114.363000,450.351000,114.431000L450.351000,105.478000C450.536000,105.370000,450.876000,105.188000,451.154000,105.083000C452.359000,104.630000,454.182000,104.260000,456.346000,103.992000C458.633000,103.708000,461.352000,103.545000,464.257000,103.545000C467.162000,103.545000,469.882000,103.708000,472.169000,103.992000C474.363000,104.260000,476.155000,104.630000,477.360000,105.083000C477.638000,105.188000,477.978000,105.370000,478.164000,105.478000Z" opacity="0.9" clipRule="evenodd" fill="rgb(7,4,254)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                        </g>
                        <g id="blocks-dispersed-g66" opacity="0.9">
                          <g id="blocks-dispersed-g67" opacity="0.75">
                            <g id="blocks-dispersed-g68" opacity="0.75">
                              <path id="blocks-dispersed-path49" d="M448.806000,104.846000C448.806000,103.144000,455.728000,101.762000,464.257000,101.762000C472.787000,101.762000,479.709000,103.144000,479.709000,104.846000L479.709000,117.178000L479.709000,117.147000C479.709000,117.156000,479.709000,117.169000,479.709000,117.178000C479.709000,118.880000,472.787000,120.261000,464.257000,120.261000C455.728000,120.261000,448.806000,118.880000,448.806000,117.178000L448.806000,104.846000ZM458.324000,114.329000C452.761000,114.792000,448.837000,115.889000,448.806000,117.169000C448.837000,115.895000,452.731000,114.804000,458.262000,114.338000L458.324000,114.329000ZM458.756000,114.295000C458.818000,114.292000,458.880000,114.286000,458.942000,114.283000L459.035000,114.277000C458.911000,114.283000,458.787000,114.292000,458.695000,114.301000L458.756000,114.295000Z" opacity="0.75" clipRule="evenodd" fill="rgb(235,141,208)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                            <g id="blocks-dispersed-g69" opacity="0.75">
                              <path id="blocks-dispersed-path50" d="M479.709000,104.893000L479.709000,117.226000C479.709000,118.928000,472.786000,120.309000,464.257000,120.309000C455.728000,120.309000,448.805000,118.928000,448.805000,117.226000L448.805000,104.893000C448.805000,106.595000,455.728000,107.976000,464.257000,107.976000C472.786000,107.976000,479.709000,106.595000,479.709000,104.893000Z" opacity="0.75" clipRule="evenodd" fill={`url(#blocks-dispersed-path50-fill-${unique})`} fillRule="evenodd" stroke="none" strokeWidth="1" />
                              <path id="blocks-dispersed-path51" d="M448.805000,104.893000C448.805000,106.595000,455.728000,107.976000,464.257000,107.976000C472.786000,107.976000,479.709000,106.595000,479.709000,104.893000L479.709000,117.226000C479.709000,118.928000,472.786000,120.309000,464.257000,120.309000C455.728000,120.309000,448.805000,118.928000,448.805000,117.226000L448.805000,104.893000ZM478.164000,107.881000L478.164000,116.834000C477.978000,116.942000,477.638000,117.124000,477.360000,117.229000C476.155000,117.682000,474.363000,118.052000,472.169000,118.320000C469.882000,118.604000,467.162000,118.767000,464.257000,118.767000C461.352000,118.767000,458.633000,118.604000,456.346000,118.320000C454.182000,118.052000,452.359000,117.682000,451.154000,117.229000C450.876000,117.124000,450.536000,116.942000,450.351000,116.834000L450.351000,107.881000C450.536000,107.949000,450.721000,108.013000,450.938000,108.075000C453.812000,108.932000,458.694000,109.518000,464.257000,109.518000C469.820000,109.518000,474.703000,108.932000,477.577000,108.075000C477.793000,108.013000,477.978000,107.949000,478.164000,107.881000Z" opacity="0.75" clipRule="evenodd" fill="rgb(255,200,255)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g id="blocks-dispersed-g70" opacity="0.9">
                        <g id="blocks-dispersed-g71" opacity="0.9">
                          <path id="blocks-dispersed-path52" d="M464.020000,126.814000L464.020000,114.482000C464.020000,112.780000,457.097000,111.399000,448.568000,111.399000C440.039000,111.399000,433.116000,112.780000,433.116000,114.482000L433.116000,126.814000C433.116000,125.112000,440.039000,123.731000,448.568000,123.731000C457.097000,123.731000,464.020000,125.112000,464.020000,126.814000ZM462.474000,114.873000L462.474000,123.827000C462.289000,123.759000,462.104000,123.694000,461.887000,123.633000C459.013000,122.775000,454.130000,122.190000,448.568000,122.190000C443.005000,122.190000,438.122000,122.775000,435.248000,123.633000C435.032000,123.694000,434.847000,123.759000,434.661000,123.827000L434.661000,114.873000C434.847000,114.766000,435.187000,114.584000,435.465000,114.479000C436.670000,114.026000,438.493000,113.656000,440.656000,113.387000C442.943000,113.104000,445.663000,112.940000,448.568000,112.940000C451.473000,112.940000,454.192000,113.104000,456.479000,113.387000C458.673000,113.656000,460.466000,114.026000,461.671000,114.479000C461.949000,114.584000,462.289000,114.766000,462.474000,114.873000Z" opacity="0.9" clipRule="evenodd" fill="rgb(7,4,254)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                        </g>
                        <g id="blocks-dispersed-g72" opacity="0.9">
                          <g id="blocks-dispersed-g73" opacity="0.75">
                            <g id="blocks-dispersed-g74" opacity="0.75">
                              <path id="blocks-dispersed-path53" d="M433.120000,114.241000C433.120000,112.539000,440.042000,111.158000,448.571000,111.158000C457.101000,111.158000,464.023000,112.539000,464.023000,114.241000L464.023000,126.573000L464.023000,126.542000C464.023000,126.552000,464.023000,126.564000,464.023000,126.573000C464.023000,128.275000,457.101000,129.656000,448.571000,129.656000C440.042000,129.656000,433.120000,128.275000,433.120000,126.573000L433.120000,114.241000ZM442.638000,123.724000C437.075000,124.187000,433.151000,125.284000,433.120000,126.564000C433.151000,125.291000,437.045000,124.199000,442.576000,123.734000L442.638000,123.724000ZM443.070000,123.690000C443.132000,123.687000,443.194000,123.681000,443.256000,123.678000L443.349000,123.672000C443.225000,123.678000,443.101000,123.687000,443.009000,123.697000L443.070000,123.690000Z" opacity="0.75" clipRule="evenodd" fill="rgb(235,141,208)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                            <g id="blocks-dispersed-g75" opacity="0.75">
                              <path id="blocks-dispersed-path54" d="M464.023000,114.289000L464.023000,126.622000C464.023000,128.324000,457.101000,129.705000,448.571000,129.705000C440.042000,129.705000,433.120000,128.324000,433.120000,126.622000L433.120000,114.289000C433.120000,115.991000,440.042000,117.372000,448.571000,117.372000C457.101000,117.372000,464.023000,115.991000,464.023000,114.289000Z" opacity="0.75" clipRule="evenodd" fill={`url(#blocks-dispersed-path54-fill-${unique})`} fillRule="evenodd" stroke="none" strokeWidth="1" />
                              <path id="blocks-dispersed-path55" d="M433.120000,114.289000C433.120000,115.991000,440.042000,117.372000,448.571000,117.372000C457.101000,117.372000,464.023000,115.991000,464.023000,114.289000L464.023000,126.622000C464.023000,128.324000,457.101000,129.705000,448.571000,129.705000C440.042000,129.705000,433.120000,128.324000,433.120000,126.622000L433.120000,114.289000ZM462.478000,117.277000L462.478000,126.230000C462.292000,126.338000,461.952000,126.520000,461.674000,126.625000C460.469000,127.078000,458.677000,127.448000,456.483000,127.716000C454.196000,128,451.476000,128.163000,448.571000,128.163000C445.666000,128.163000,442.947000,128,440.660000,127.716000C438.497000,127.448000,436.674000,127.078000,435.468000,126.625000C435.190000,126.520000,434.850000,126.338000,434.665000,126.230000L434.665000,117.277000C434.850000,117.345000,435.035000,117.409000,435.252000,117.471000C438.126000,118.328000,443.009000,118.914000,448.571000,118.914000C454.134000,118.914000,459.017000,118.328000,461.891000,117.471000C462.107000,117.409000,462.292000,117.345000,462.478000,117.277000Z" opacity="0.75" clipRule="evenodd" fill="rgb(255,200,255)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g id="blocks-dispersed-g76" opacity="0.9">
                        <g id="blocks-dispersed-g77" opacity="0.9">
                          <path id="blocks-dispersed-path56" d="M540.916000,117.419000L540.916000,105.087000C540.916000,103.385000,533.994000,102.004000,525.465000,102.004000C516.935000,102.004000,510.013000,103.385000,510.013000,105.087000L510.013000,117.419000C510.013000,115.718000,516.935000,114.336000,525.465000,114.336000C533.994000,114.336000,540.916000,115.718000,540.916000,117.419000ZM539.371000,105.479000L539.371000,114.432000C539.186000,114.364000,539,114.299000,538.784000,114.238000C535.910000,113.381000,531.027000,112.795000,525.465000,112.795000C519.902000,112.795000,515.019000,113.381000,512.145000,114.238000C511.929000,114.299000,511.744000,114.364000,511.558000,114.432000L511.558000,105.479000C511.744000,105.371000,512.083000,105.189000,512.362000,105.084000C513.567000,104.631000,515.390000,104.261000,517.553000,103.993000C519.840000,103.709000,522.560000,103.546000,525.465000,103.546000C528.370000,103.546000,531.089000,103.709000,533.376000,103.993000C535.570000,104.261000,537.362000,104.631000,538.568000,105.084000C538.846000,105.189000,539.186000,105.371000,539.371000,105.479000Z" opacity="0.9" clipRule="evenodd" fill="rgb(7,4,254)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                        </g>
                        <g id="blocks-dispersed-g78" opacity="0.9">
                          <g id="blocks-dispersed-g79" opacity="0.75">
                            <g id="blocks-dispersed-g80" opacity="0.75">
                              <path id="blocks-dispersed-path57" d="M510.016000,104.845000C510.016000,103.143000,516.938000,101.762000,525.468000,101.762000C533.997000,101.762000,540.919000,103.143000,540.919000,104.845000L540.919000,117.177000L540.919000,117.146000C540.919000,117.156000,540.919000,117.168000,540.919000,117.177000C540.919000,118.879000,533.997000,120.260000,525.468000,120.260000C516.938000,120.260000,510.016000,118.879000,510.016000,117.177000L510.016000,104.845000ZM519.534000,114.328000C513.972000,114.791000,510.047000,115.888000,510.016000,117.168000C510.047000,115.895000,513.941000,114.803000,519.473000,114.338000L519.534000,114.328000ZM519.967000,114.294000C520.029000,114.291000,520.091000,114.285000,520.153000,114.282000L520.245000,114.276000C520.122000,114.282000,519.998000,114.291000,519.905000,114.301000L519.967000,114.294000Z" opacity="0.75" clipRule="evenodd" fill="rgb(235,141,208)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                            <g id="blocks-dispersed-g81" opacity="0.75">
                              <path id="blocks-dispersed-path58" d="M540.920000,104.893000L540.920000,117.225000C540.920000,118.927000,533.998000,120.308000,525.469000,120.308000C516.939000,120.308000,510.017000,118.927000,510.017000,117.225000L510.017000,104.893000C510.017000,106.595000,516.939000,107.976000,525.469000,107.976000C533.998000,107.976000,540.920000,106.595000,540.920000,104.893000Z" opacity="0.75" clipRule="evenodd" fill={`url(#blocks-dispersed-path58-fill-${unique})`} fillRule="evenodd" stroke="none" strokeWidth="1" />
                              <path id="blocks-dispersed-path59" d="M510.017000,104.893000C510.017000,106.595000,516.939000,107.976000,525.469000,107.976000C533.998000,107.976000,540.920000,106.595000,540.920000,104.893000L540.920000,117.225000C540.920000,118.927000,533.998000,120.308000,525.469000,120.308000C516.939000,120.308000,510.017000,118.927000,510.017000,117.225000L510.017000,104.893000ZM539.375000,107.880000L539.375000,116.834000C539.190000,116.941000,538.850000,117.123000,538.572000,117.228000C537.367000,117.681000,535.574000,118.051000,533.380000,118.320000C531.093000,118.603000,528.374000,118.767000,525.469000,118.767000C522.564000,118.767000,519.844000,118.603000,517.557000,118.320000C515.394000,118.051000,513.571000,117.681000,512.366000,117.228000C512.088000,117.123000,511.748000,116.941000,511.562000,116.834000L511.562000,107.880000C511.748000,107.948000,511.933000,108.013000,512.149000,108.075000C515.023000,108.932000,519.906000,109.517000,525.469000,109.517000C531.031000,109.517000,535.914000,108.932000,538.788000,108.075000C539.005000,108.013000,539.190000,107.948000,539.375000,107.880000Z" opacity="0.75" clipRule="evenodd" fill="rgb(255,200,255)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g id="blocks-dispersed-g82" opacity="0.9">
                        <g id="blocks-dispersed-g83" opacity="0.9">
                          <path id="blocks-dispersed-path60" d="M525.215000,126.814000L525.215000,114.482000C525.215000,112.780000,518.292000,111.399000,509.763000,111.399000C501.234000,111.399000,494.311000,112.780000,494.311000,114.482000L494.311000,126.814000C494.311000,125.112000,501.234000,123.731000,509.763000,123.731000C518.292000,123.731000,525.215000,125.112000,525.215000,126.814000ZM523.669000,114.873000L523.669000,123.827000C523.484000,123.759000,523.299000,123.694000,523.082000,123.633000C520.208000,122.775000,515.326000,122.190000,509.763000,122.190000C504.200000,122.190000,499.317000,122.775000,496.443000,123.633000C496.227000,123.694000,496.042000,123.759000,495.856000,123.827000L495.856000,114.873000C496.042000,114.766000,496.382000,114.584000,496.660000,114.479000C497.865000,114.026000,499.688000,113.656000,501.852000,113.387000C504.138000,113.104000,506.858000,112.940000,509.763000,112.940000C512.668000,112.940000,515.388000,113.104000,517.674000,113.387000C519.869000,113.656000,521.661000,114.026000,522.866000,114.479000C523.144000,114.584000,523.484000,114.766000,523.669000,114.873000Z" opacity="0.9" clipRule="evenodd" fill="rgb(7,4,254)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                        </g>
                        <g id="blocks-dispersed-g84" opacity="0.9">
                          <g id="blocks-dispersed-g85" opacity="0.75">
                            <g id="blocks-dispersed-g86" opacity="0.75">
                              <path id="blocks-dispersed-path61" d="M494.307000,114.241000C494.307000,112.539000,501.229000,111.158000,509.759000,111.158000C518.288000,111.158000,525.210000,112.539000,525.210000,114.241000L525.210000,126.573000L525.210000,126.542000C525.210000,126.552000,525.210000,126.564000,525.210000,126.573000C525.210000,128.275000,518.288000,129.656000,509.759000,129.656000C501.229000,129.656000,494.307000,128.275000,494.307000,126.573000L494.307000,114.241000ZM503.825000,123.724000C498.262000,124.187000,494.338000,125.284000,494.307000,126.564000C494.338000,125.291000,498.232000,124.199000,503.763000,123.734000L503.825000,123.724000ZM504.258000,123.690000C504.319000,123.687000,504.381000,123.681000,504.443000,123.678000L504.536000,123.672000C504.412000,123.678000,504.289000,123.687000,504.196000,123.697000L504.258000,123.690000Z" opacity="0.75" clipRule="evenodd" fill="rgb(235,141,208)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                            <g id="blocks-dispersed-g87" opacity="0.75">
                              <path id="blocks-dispersed-path62" d="M525.210000,114.289000L525.210000,126.622000C525.210000,128.324000,518.288000,129.705000,509.759000,129.705000C501.229000,129.705000,494.307000,128.324000,494.307000,126.622000L494.307000,114.289000C494.307000,115.991000,501.229000,117.372000,509.759000,117.372000C518.288000,117.372000,525.210000,115.991000,525.210000,114.289000Z" opacity="0.75" clipRule="evenodd" fill={`url(#blocks-dispersed-path62-fill-${unique})`} fillRule="evenodd" stroke="none" strokeWidth="1" />
                              <path id="blocks-dispersed-path63" d="M525.210000,114.289000L525.210000,126.622000C525.210000,128.324000,518.288000,129.705000,509.759000,129.705000C501.229000,129.705000,494.307000,128.324000,494.307000,126.622000L494.307000,114.289000C494.307000,115.991000,501.229000,117.372000,509.759000,117.372000C518.288000,117.372000,525.210000,115.991000,525.210000,114.289000ZM523.665000,117.277000L523.665000,126.230000C523.480000,126.338000,523.140000,126.520000,522.862000,126.625000C521.656000,127.078000,519.864000,127.448000,517.670000,127.716000C515.383000,128,512.663000,128.163000,509.759000,128.163000C506.854000,128.163000,504.134000,128,501.847000,127.716000C499.684000,127.448000,497.861000,127.078000,496.656000,126.625000C496.377000,126.520000,496.037000,126.338000,495.852000,126.230000L495.852000,117.277000C496.037000,117.345000,496.223000,117.409000,496.439000,117.471000C499.313000,118.328000,504.196000,118.914000,509.759000,118.914000C515.321000,118.914000,520.204000,118.328000,523.078000,117.471000C523.294000,117.409000,523.480000,117.345000,523.665000,117.277000Z" opacity="0.75" clipRule="evenodd" fill="rgb(255,200,255)" fillRule="evenodd" stroke="none" strokeWidth="1" />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </BlocksDispersedStyled>
            </div>
          </div>
        </article>
        <article className="step second" ref={secondRow}>
          <div className="blocks-moving-container" ref={secondGosth}>
            <img
              ref={secondCubes}
              className="gosth blocks-moving"
              src="/images/course/blocks-partial.png"
              alt="Linea de bloques opaca"
              width="404"
              height="123"
              loading="lazy"
            />
          </div>
          <div ref={secondText}>
            <Typography small="regular" large="headline5">
              Por medio de piezas pequeñas, comienzas a construir tu escalera de conocimiento continuo.
            </Typography>
          </div>
        </article>
        <article className="step third">
          <div ref={thirdText}>
            <Typography small="regular" large="headline5">
              Cada peldaño es importante para el siguiente.
            </Typography>
          </div>
          <img
            ref={thirdGosth}
            className="gosth completed mobile-hidden"
            src="/images/course/blocks-completed.svg"
            alt="grupo de bloques opacos"
            loading="lazy"
            width="578"
            height="342"
          />
          <img
            src="/images/course/blocks-completed-full.png"
            alt="grupo de bloques opacos"
            className="desktop-hidden"
            width="578"
            height="342"
            loading="lazy"
          />
        </article>
      </div>
    </>
  )
})

export default BlocksDispersed
